import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Trip } from '@app/domain/interfaces/trip.interfaces';
import { User } from '@app/domain/interfaces/user.interfaces';
import { CreateTripDto } from '@app/infrastructure/dtos/trip/create-trip.dto';
import { UpdateTripDto } from '@app/infrastructure/dtos/trip/update-trip.dto';
import { HttpBaseService } from '@app/infrastructure/http/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TripsApiService extends HttpBaseService {
  private readonly baseUrl = 'trips';

  findById(id: string): Observable<Trip> {
    return this.Get(`${this.baseUrl}/${id}`);
  }

  find(username: string, urlSlug: string): Observable<Trip<User>> {
    return this.Get(this.baseUrl, { params: this.toHttpParams({ username, urlSlug }) });
  }

  feed(page: number): Observable<{ trips: Trip<User>[]; count: number }> {
    return this.Get(`${this.baseUrl}/list/feed`, { params: this.toHttpParams({ page }) });
  }

  list(searchText?: string): Observable<{ trips: Trip<User>[]; count: number }> {
    return this.Get(`${this.baseUrl}/list/all`, { params: this.toHttpParams({ searchText }) });
  }

  getMine(): Observable<{ trips: Trip<User>[]; count: { all?: number; public: number } }> {
    return this.Get(`${this.baseUrl}/list/mine`);
  }

  getByUser(userId: string): Observable<{ trips: Trip<User>[]; count: { all?: number; public: number } }> {
    return this.Get(`${this.baseUrl}/list/user/${userId}`);
  }

  getLatest(): Observable<Trip<User>[]> {
    const headers = new HttpHeaders({ loader: String(false) });

    return this.Get(`${this.baseUrl}/list/latest`, { headers });
  }

  create(body: CreateTripDto): Observable<void> {
    return this.Post(this.baseUrl, body);
  }

  update(id: string, body: UpdateTripDto): Observable<void> {
    return this.Put(`${this.baseUrl}/${id}`, body);
  }

  remove(id: string): Observable<void> {
    return this.Delete(`${this.baseUrl}/${id}`);
  }
}
